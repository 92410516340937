import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

export const TABLA_PARTIDAS = 'EurekaGmJuego'

export const partidasAPI = createApi({
    reducerPath: 'partidasAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Partidas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerPartida: builder.query({
            /**
             * 
             * @param {{ token: string, col: string, val: string, relaciones: array }} data 
             * @returns 
             */
            query: ({ id }) => {
                let body = new EkQuery(TABLA_PARTIDAS)
                    .with(['eureka_ac_usuario',
                        'eureka_gm_juego_equipos.eureka_gm_juego_marcadores',
                        "eureka_gm_juego_equipos.eureka_gm_equipo.eureka_gm_equipo_integrantes"])
                    .where(id, '=', id)

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.first()
                }
            },
        }),
        editarPartida: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: ({ id, data }) => {
                let body = new EkQuery(TABLA_PARTIDAS)
                if (id) body = body.where("id", id).update(data)
                else body = body.create(data)
                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
            invalidatesTags: ['Partidas'],
        }),
        obtenerInfoPartida: builder.mutation({
            /**
             * 
             * @param {{ id: number }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_PARTIDAS).where('id', data.id).first()
                }
            },
        }),
        obtenerPartidas: builder.query({
            /**
             * @param {{ id: number }} data 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_PARTIDAS)
                        .with(['eureka_ac_usuario',
                            'eureka_gm_juego_equipos.eureka_gm_juego_marcadores',
                            "eureka_gm_juego_equipos.eureka_gm_equipo.eureka_gm_equipo_integrantes"])
                        .get()
                }
            },
            providesTags: ['Partidas'],
        }),
    }),
})


export const {
    useObtenerPartidaQuery,
    useEditarPartidaMutation,
    useObtenerInfoPartidaMutation,
    useObtenerPartidasQuery,
} = partidasAPI