import { PerfilCard, PerfilDetalleSection, PerfilDetalleInfo, ChipEstatus, ChipContact } from "eureka-design"
import { useObtenerPartidaQuery } from "../../services/partidas"
import { obtenerFoto } from '../../js/multimedia'

export default function Partida({ partida }) {
    const { data } = useObtenerPartidaQuery({ id: partida })

    const Estatus = (estatus) => {
        let chip;
        if (estatus === "terminado") {
            chip = <ChipEstatus texto={"Terminado"} tipo="Aceptado" />
        }

        if (estatus === "cancelado") {
            chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
        }

        if (estatus === "proceso") {
            chip = <ChipEstatus texto={"Proceso"} tipo="Pendiente" />
        }
        return chip;
    }

    return (
        <PerfilCard
            titulo={data?.nombre}
            aspectRadio={"23/3"}
        >
            <PerfilDetalleInfo titulo="Informacion de partida">
                <PerfilDetalleSection titulo={"Fecha"}>
                    <span>{data?.fecha ?? "Sin fecha"}</span>
                </PerfilDetalleSection>
                <PerfilDetalleSection titulo={"Tipo"}>
                    <span>{data?.tipo ?? "Sin tipo"}</span>
                </PerfilDetalleSection>
                <PerfilDetalleSection titulo={"Equipo ganador"}>
                    <span>{"sin resolver"}</span>
                </PerfilDetalleSection>
                <PerfilDetalleSection titulo={"Organizador"}>
                    <ChipContact
                        texto={data?.eureka_ac_usuario?.nombre}
                        // onClick={() => {
                        //     Push("usuario", { perfilUser: perfil?.eureka_ac_usuario?.id }, "Usuarios")

                        // }}
                        error={"../../imgs/nouser.jpg"}
                        imagen={obtenerFoto(data?.eureka_ac_usuario.foto, "bs_usuarios")}
                    />
                </PerfilDetalleSection>
                <PerfilDetalleSection titulo={"Estatus de la partida"}>
                    {Estatus(data?.estatus ?? "")}
                </PerfilDetalleSection>
            </PerfilDetalleInfo>
            <PerfilDetalleInfo titulo="Equipos en la partida">
                {
                    data?.eureka_gm_juego_equipos?.map(equipo => {
                        return <PerfilDetalleSection titulo={equipo?.eureka_gm_equipo?.nombre}>
                            {/* <ChipContact
                                nombre={equipo?.eureka_gm_equipo?.nombre}
                                correo={equipo?.eureka_gm_equipo?.correo}
                                telefono={equipo?.eureka_gm_equipo?.telefono}
                            /> */}
                        </PerfilDetalleSection>
                    })
                }
            </PerfilDetalleInfo>
            <PerfilDetalleInfo titulo="Historial de marcador">
                {
                    data?.eureka_gm_juego_equipos?.map(equipo => {
                        return <>
                            <h3>{equipo?.eureka_gm_equipo?.nombre}</h3>

                            {
                                equipo?.eureka_gm_juego_marcadores?.map(marcador => {
                                    return <>
                                        <PerfilDetalleSection titulo={`Ronda ${marcador.num_marcador}`}
                                        >
                                            <span>{marcador.marcador + " - puntos"}</span>
                                        </PerfilDetalleSection>
                                    </>
                                })
                            }
                        </>

                    })
                }
            </PerfilDetalleInfo>
        </PerfilCard>
    )
}