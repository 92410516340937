import { MiPerfil } from 'eureka-design'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { useState } from 'react'
import { CARPETA_FOTO, NOM_FORM_PERFIL } from './EditarPerfil'
import { obtenerFoto } from '../../js/multimedia'

const Perfil = ({ Push }) => {
    const [usuario] = useState(infoUsuario())

    return (
        <MiPerfil 
            avatar={obtenerFoto(usuario?.foto, CARPETA_FOTO)}
            nombre={usuario?.nombre}
            correo={usuario?.correo}
            telefono={usuario?.telefono ?? 'Sin teléfono'}
            botones={[
                { 
                    iconLeft: 'fa-pen', 
                    texto: 'Editar Información', 
                    onClick: () => { 
                        Push('editar_perfil', {[NOM_FORM_PERFIL]: { ...usuario }}) 
                    } 
                },
                { 
                    iconLeft: 'fa-arrow-right-from-bracket', 
                    texto: 'Cerrar Sesión', 
                    onClick: () => { window.location.href = '/cerrar_sesion' } 
                }
            ]}
        />
    )
}

export default Perfil