import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

export const TABLA_PARTIDA = 'EurekaBsEvento'

export const partidaAPI = createApi({
    reducerPath: 'partidaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Partidas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerPartidas: builder.query({
            query: () => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_PARTIDA).get()
                }
            },
        }),
    }),
})


export const { 
    useObtenerPartidasQuery,
} = partidaAPI