import { Formulario, Input, ImageInput } from 'eureka-design'
import { useCrearMultimediaMutation } from '../../services/multimedia'
import { obtenerFoto } from '../../js/multimedia'
import { useEditarUsuarioMutation, useObtenerInfoUsuarioMutation } from '../../services/usuario'
import { crearCookie } from 'account-react-eurekasigma'
import { config } from '../../config/config'

export const NOM_FORM_PERFIL = 'form_perfil'
export const CARPETA_FOTO = 'bs_usuarios'

const EditarPerfil = ({ UpProps, form_perfil }) => {
    const [crearMultimedia, { isLoading: creandoMult, isError: multErroneo, isSuccess: multCreado }] = useCrearMultimediaMutation()
    const [editarUsuario, { isLoading: editandoUsuario, isError: usuarioErroneo, isSuccess: usuarioEditado }] = useEditarUsuarioMutation()
    const [obtenerInformacion] = useObtenerInfoUsuarioMutation()

    const handlerFoto = (e) => {
        crearMultimedia({
            tabla: 'EurekaAcUsuario',
            media: {
                id: form_perfil.id,
                file: e.target.files[0],
                col: 'foto',
                carpeta: CARPETA_FOTO
            }
        })
            .then(() => {
                obtenerInformacion({ id: form_perfil.id })
                    .then(res => {
                        hanlderProps('foto', res.data.foto)
                        crearCookie(
                            config.nomCookieUsuario,
                            JSON.stringify({
                                ...form_perfil,
                                foto: res.data.foto
                            }),
                            true,
                            config.expCookie
                        )
                    })
            })
    }

    const handlerUsuario = (nom, val) => {
        editarUsuario({
            id: form_perfil.id,
            body: {
                [nom]: val
            }
        })
            .then(() => {
                crearCookie(
                    config.nomCookieUsuario,
                    JSON.stringify({
                        ...form_perfil,
                        [nom]: val
                    }),
                    true,
                    config.expCookie
                )
            })
    }

    const handlerInput = (val, nom) => {
        handlerUsuario(nom, val)
        hanlderProps(nom, val)
    }

    const hanlderProps = (nom, val) => {
        UpProps({
            [NOM_FORM_PERFIL]: {
                ...form_perfil,
                [nom]: val
            }
        })
    }

    return (
        <Formulario
            titulo="Editar Información"
            isLoading={[creandoMult, editandoUsuario]}
            isError={[multErroneo, usuarioErroneo]}
            isSuccess={[multCreado, usuarioEditado]}
        >
            <ImageInput
                avatar={true}
                width={200}
                height={200}
                preview={obtenerFoto(form_perfil?.foto, CARPETA_FOTO)}
                changeFunction={handlerFoto}
            />
            <Input
                type="text"
                nombre="nombre"
                iconLeft="fa-signature"
                placeholder="Nombre"
                value={form_perfil.nombre}
                changeFunction={handlerInput}
                isLoading={editandoUsuario}
            />
            <Input
                type="text"
                nombre="telefono"
                iconLeft="fa-phone"
                placeholder="Teléfono"
                value={form_perfil?.telefono}
                changeFunction={handlerInput}
                isLoading={editandoUsuario}
            />
        </Formulario>
    )
}

export default EditarPerfil