import { Tabla, buildData, ChipEstatus, ChipContact } from 'eureka-design'
import { useEffect, useState } from 'react'
import { obtenerFoto } from '../../js/multimedia'

import { useObtenerDispositivosQuery, useEditarDispositivoMutation } from '../../services/dispositivo'

const Dispositivos = ({ Push }) => {
    const { data } = useObtenerDispositivosQuery()
    const [keys, setKeys] = useState([])
    const [dispositivos, setDispositivos] = useState([])
    const [UpDispositivo, UpStatus] = useEditarDispositivoMutation()

    useEffect(() => {
        if (data) {
            let dispositivos = data.length
            let funcionando = data.filter(item => item.estatus === 'funcionando')
            let descompuestos = data.filter(item => item.estatus === 'descompuesto')
            let en_reparacion = data.filter(item => item.estatus === 'reparacion')
            let mantenimiento = data.filter(item => item.estatus === 'mantenimiento')
            setKeys([
                {
                    text: "Todos",
                    detalle: dispositivos,
                    icono: "fa-microchip",
                    filtro: (item) => {
                        return true
                    }
                },
                {
                    text: "Funcionando",
                    detalle: funcionando.length,
                    icono: "fa-circle-check",
                    filtro: (item) => {
                        return item.item.estatus === 'funcionando'
                    }
                },
                {
                    text: "En envió",
                    detalle: mantenimiento.length,
                    icono: "fa-spinner",
                    filtro: (item) => item.estatus === 'mantenimiento'
                },
                {
                    text: "En almacén",
                    detalle: en_reparacion.length,
                    icono: "fa-warehouse",
                    filtro: (item) => item.estatus === 'reparacion'
                }
            ])

            let data_dispositivos = make_data(data)
            setDispositivos(data_dispositivos)
        }
    }, [data])

    function make_data(data) {
        return data.map(item => {
            return {
                ...item,
                estatus: item.estatus
            }
        }
        )
    }


    return (
        <Tabla
            checkbox={false}
            noData="No hay dispositivos registrados"
            busqueda={{ placeholder: "Buscar...", keys: ["usuario"] }}
            keys={keys}
            headers={[
                { key: 'numero_identificador', text: 'Código', type: 'text' },
                { key: 'usuario', text: 'Propietario', type: 'text' },
                { key: 'estatus', text: 'Estatus', type: 'text' },
            ]}
            data={buildData(dispositivos, [
                { key: 'numero_identificador', type: 'text' },
                {
                    key: 'usuario', type: 'text', text: (item) => {
                        if (item.eureka_ac_usuario != null) {
                            return <ChipContact
                                texto={item.eureka_ac_usuario.nombre}
                                imagen={obtenerFoto(item.eureka_ac_usuario.foto, "bs_usuarios")}
                            />

                        } else {
                            return <span>Sin propietario</span>
                        }

                    }
                },
                {
                    key: 'estatus',
                    type: 'text',
                    text: (item) => {
                        if (item.estatus === 'funcionando') {
                            return <ChipEstatus texto={"Funcionando"} tipo="Aceptado" />
                        } else if (item.estatus === 'reparacion') {
                            return <ChipEstatus texto={"En almacen"} tipo="Error" />
                        } else if (item.estatus === 'mantenimiento') {
                            return <ChipEstatus texto={"En envió"} tipo="Pendiente" />
                        }

                    },
                },
            ])}
            botonesHead={[
                {
                    icono: 'fa-plus',
                    tooltip: 'Agregar',
                    onClick: () => {
                        Push('form_dispositivos')
                    }
                }
            ]}
            botonesRow={[
                {
                    icono: 'fa-edit',
                    tooltip: 'Editar',
                    onClick: (item) => {
                        item = item.item
                        Push('form_dispositivos', {
                            dispositivo: item.id,
                            numero_identificador: item.numero_identificador,
                            descripcion: item.descripcion,
                            Propietario_nombre: item.eureka_ac_usuario?.nombre ?? null,
                            Propietario_id: item.eureka_ac_usuario?.id ?? null,
                        })
                    }
                },
                [
                    {
                        icono: 'fa-spinner',
                        tooltip: 'Envió',
                        mutacion: (item) => { return item.estatus === "funcionando" },
                        onClick: (item) => {
                            if (!UpStatus.isLoading) {
                                UpDispositivo({ id: item.id, data: { estatus: 'mantenimiento' } })
                            }
                        }
                    },
                    {
                        icono: 'fa-warehouse',
                        tooltip: 'Almacén',
                        mutacion: (item) => { return item.estatus === "funcionando" },
                        onClick: (item) => {
                            if (!UpStatus.isLoading) {
                                UpDispositivo({ id: item.id, data: { estatus: 'reparacion' } })
                            }
                        }
                    },
                    {
                        icono: 'fa-circle-check',
                        tooltip: 'Funcionando',
                        mutacion: (item) => {
                            return item.estatus === "mantenimiento" || item.estatus === "reparacion" || item.estatus === "descompuesto"
                        },
                        onClick: (item) => {
                            if (!UpStatus.isLoading) {
                                UpDispositivo({ id: item.id, data: { estatus: 'funcionando' } })
                            }
                        }
                    },

                ]
            ]}
            onClickRow={() => { }}
        />
    )
}

export default Dispositivos