import { Formulario, Input, LoaderCircle, ImageInput, Chip } from "eureka-design"
import { useEditarUsuarioMutation, useUploadImageUsuariosMutation, useGetRolesQuery, useAgregarRolMutation, useRemoverRolMutation } from "../../services/usuario"
import { useState } from "react"

export const NOM_FORM_USUARIO = 'form_usuario'

const FormUsuario = ({ UpProps, form_usuario, idUsuario }) => {
    const [id, fijaId] = useState(form_usuario?.id)

    const [crearUsuario, { isLoading: creandoUsuario, isError: usuarioErroneo, isSuccess: usuarioCreado }] = useEditarUsuarioMutation()
    const [uploadFoto, { isLoading: imageisLoading, isError: imageIsError, isSuccess: imageIsSuccess }] = useUploadImageUsuariosMutation();
    const [agregarRol, { isLoading: rolLoading, isError: rolError, isSuccess: rolSusccess }] = useAgregarRolMutation();
    const [removerRol, { isLoading: rolRemLoading, isError: rolRemError, isSuccess: rolRemSusccess }] = useRemoverRolMutation();
    const { data: Roles, isLoading: getRolesLoading } = useGetRolesQuery({ usuario: form_usuario?.id });

    const handlearUsuario = async (nom, val) => {
        return await crearUsuario({
            id: id,
            body: {
                [nom]: val
            }
        })
            .then(res => {
                if (res?.data?.mensaje?.id) {
                    fijaId(res?.data?.mensaje?.id)
                    return res?.data?.mensaje?.id
                }
                else return id
            })
    }

    const handlerUsuarioPhoto = async (file, carpeta, nombre) => {
        return await uploadFoto({
            id: id,
            file: file,
            carpeta: carpeta,
            nombre: nombre
        })
            .then(res => {
                if (res?.data?.mensaje?.id) {
                    fijaId(res?.data?.mensaje?.id)
                    return res?.data?.mensaje?.id
                }
                else return id
            })
    }

    const handlerInput = (val, nom) => {
        handlearUsuario(nom, val)
        handlerProps(nom, val)
    }

    const handlerPhoto = (e) => {
        handlerUsuarioPhoto(e.target.files[0], "bs_usuarios", "foto")
        handlerProps("foto", e.target.files[0])
    }

    const handlerProps = (nom, val) => {
        UpProps({
            [NOM_FORM_USUARIO]: {
                ...form_usuario,
                id: id,
                [nom]: val
            }
        })
    }

    return (
        <Formulario
            titulo="Información del Usuario"
            isLoading={[creandoUsuario, imageisLoading, rolLoading, rolRemLoading]}
            isError={[usuarioErroneo, imageIsError, rolError, rolRemError]}
            isSuccess={[usuarioCreado, imageIsSuccess, rolSusccess, rolRemSusccess]}
        >
            <ImageInput
                width={250}
                height={250}
                avatar={true}
                changeFunction={handlerPhoto}
                preview={form_usuario.prevUrl}
                file={form_usuario.prevFile}
            >
            </ImageInput>
            <Input
                type="text"
                nombre="nombre"
                iconLeft="fa-signature"
                placeholder="Nombre"
                value={form_usuario.nombre}
                changeFunction={handlerInput}
                isLoading={creandoUsuario}
            />
            <Input
                type="text"
                nombre="correo"
                iconLeft="fa-envelope"
                placeholder="Correo"
                value={form_usuario.correo}
                changeFunction={handlerInput}
                isLoading={creandoUsuario}
            />
            <Input
                type="text"
                nombre="telefono"
                iconLeft="fa-phone"
                placeholder="Teléfono"
                value={form_usuario.telefono}
                changeFunction={handlerInput}
                isLoading={creandoUsuario}
            />

            <h4>Roles de usuario</h4>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                flexWrap: "wrap"
            }}>
                {!Roles && getRolesLoading ? <LoaderCircle /> :
                    Roles?.map((item, index) => {
                        let select = false;
                        let onClick;

                        if (item.eureka_cr_rol_usuarios.length > 0) {
                            select = true;
                            onClick = (upSelect) => {
                                upSelect(false)
                                if (!rolRemLoading && !rolLoading && !getRolesLoading) removerRol({ rol: item.id, usuario: form_usuario?.id })
                            }
                        } else {
                            onClick = (upSelect) => {
                                upSelect(true)
                                if (form_usuario?.id !== null && !rolLoading && !rolRemLoading && !getRolesLoading) {
                                    agregarRol({ rol: item.id, usuario: form_usuario?.id })
                                } else if (!rolLoading && !rolRemLoading && !getRolesLoading) {
                                    handlerInput("nombre", "", item.id)
                                }
                            }
                        }
                        return <Chip key={index} selected={select} texto={item.nombre} onClick={onClick} icono={"fa-user-tag"}></Chip>
                    })
                }
            </div>
        </Formulario>
    )
}

export default FormUsuario