import { createBrowserRouter } from 'react-router-dom'
import { NotFound } from 'eureka-design'
import Inicio from '../pages/procesamiento_sesion/Inicio'
import InicioSesion from '../pages/procesamiento_sesion/InicioSesion'
import ProcesarCredenciales from '../pages/procesamiento_sesion/ProcesarCredenciales'
import TerminarSesion from '../pages/procesamiento_sesion/TerminarSesion'

export const rutas = createBrowserRouter([
    {
        path: '/',
        element: <Inicio />,
        errorElement: <NotFound />
    },
    {
        path: '/iniciar_sesion',
        element: <InicioSesion />,
        errorElement: <NotFound />
    },
    {
        path: '/procesar_credenciales',
        element: <ProcesarCredenciales />,
        errorElement: <NotFound />
    },
    {
        path: '/cerrar_sesion',
        element: <TerminarSesion />,
        errorElement: <NotFound />
    },
]) 