import { DIA, MIN } from 'account-react-eurekasigma'

export const DOMINIO = window.location.origin
const URL_LOCAL = 'http://localhost:3000'
const URL_BASE_CRUFDEK = 'https://crud.eksuite.com/api'

export const config = {
    contraseniaApp: 'padel_skoreball_web_2024',
    colorPrimario: '#04454f',
    colorSecundario: ' #021f24',
    keyAccount: DOMINIO === URL_LOCAL ? 'Sc5YuIoIapS0xKGEOkhaNnTBdUuzrvaRFNhxvKdW' : '',
    keyBD: '6fEcvxff4TorBYwyK7K3CLmH0nJLu8vGmJLgs2We',
    urlCRUFDEK: `${URL_BASE_CRUFDEK}/v2/CRUFDEK`,
    urlCRUFDEKArchivo: `${URL_BASE_CRUFDEK}/GetFile`,
    expCookie: { cantidad: 15, tipo: DIA },
    inactividad: { cantidad: 15, tipo: DIA },
    tiempoRefrescar: { cantidad: 50, tipo: MIN },
    nomCookieUsuario: 'usuario',
    nomCookieToken: 'tokenSesion',
}