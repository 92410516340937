import { config } from '../../config/config'
import { handler_rol } from "../../js/handler_rol.ts";
import { useObtenerRolesQuery } from "../../services/usuario";
import iconoEmpresa from '../../imgs/padelsmall.svg'
import { Theme, Navegador, LoaderCircle } from 'eureka-design'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import Perfil from '../perfil/Perfil'
import Partidas from '../partidas/Partidas'
import Partida from '../partidas/partida'
import Usuarios from '../usuarios/Usuarios'
import Dispositivos from '../dispositivos/Dispositivos'
import { useState } from 'react'
import EditarPerfil, { CARPETA_FOTO } from '../perfil/EditarPerfil'
import { obtenerFoto } from '../../js/multimedia'
import { capitalize } from '../../js/util'
import FormUsuario from '../usuarios/FormUsuario'
import FormPartida from '../partidas/FormPartida'
import FormDispositivo from '../dispositivos/FormDispositivo'
import { useEffect } from 'react';

const Index = () => {
    const [usuario] = useState(infoUsuario())
    const { data: rolesUsuario, isLoading: cargandoRoles, isSuccess: rolesSuccess } = useObtenerRolesQuery({ usuarioID: usuario.id })
    const [getPermiso, isAdmin] = handler_rol(rolesUsuario)
    const [ListaComponentes, setListaComponentes] = useState({
        "Cargando": {
            pantallas: {
                cargando: {
                    titulo: "Cargando roles...",
                    componente: () => (<div style={{ width: "max-content", height: "max-content", margin: "auto" }}>
                        <LoaderCircle size="large"></LoaderCircle>
                    </div>)
                },
            },
        }
    })

    useEffect(() => {
        if (rolesSuccess) {
            BuildVistas();
        }
    }, [rolesSuccess])

    function BuildVistas() {
        const lista = {};
        lista.Usuarios = {
            opcion: {
                texto: "Lista de usuarios",
                icono: " fas fa-users",
            },
            pantallas: {},
        }

        if (getPermiso('r_usuarios')) {
            lista.Usuarios.pantallas.usuarios = {
                props: { usuario },
                titulo: 'Usuarios',
                componente: Usuarios
            }
        }
        if (getPermiso('cu_usuarios')) {
            lista.Usuarios.pantallas.form_usuarios = {
                props: { usuario, getPermiso },
                titulo: "Agregar Usuario",
                componente: FormUsuario,
            }
        }
        lista.Partidas = {
            opcion: {
                texto: "Lista de juegos",
                icono: " fas fa-table-tennis-paddle-ball",
            },
            pantallas: {},
        }
        if (getPermiso('r_partidas')) {
            lista.Partidas.pantallas.partidas = {
                props: { usuario, getPermiso },
                titulo: "Partidas",
                componente: Partidas,
            }
            lista.Partidas.pantallas.partida = {
                props: { usuario, getPermiso },
                titulo: "Partida",
                componente: Partida,
            }
        }

        lista.Dispositivos = {
            opcion: {
                texto: "Dispositivos",
                icono: "fas fa-microchip",
            },
            pantallas: {},
        }
        if (getPermiso('r_dispositivos')) {
            lista.Dispositivos.pantallas.dispositivos = {
                props: { usuario, getPermiso },
                titulo: "Dispositivos",
                componente: Dispositivos,
            }
        }
        if (getPermiso('cu_dispositivos')) {
            lista.Dispositivos.pantallas.form_dispositivos = {
                props: { usuario, getPermiso },
                titulo: "Agregar Dispositivo",
                componente: FormDispositivo,
            }
        }

        lista.Perfil = {
            pantallas: {
                perfil: {
                    props: { usuario, getPermiso },
                    titulo: "Mi perfil",
                    componente: Perfil
                },
                editar_perfil: {
                    props: {},
                    titulo: 'Mi Información',
                    componente: EditarPerfil
                }
            },
        };
        setListaComponentes(lista)
    }

    let opciones = {
        'Usuarios': {
            opcion: {
                texto: 'Usuarios',
                icono: 'fa-users'
            },
            pantallas: {
                usuarios: {
                    props: { usuario },
                    titulo: 'Usuarios',
                    componente: Usuarios
                },
                form_usuarios: {
                    props: {},
                    titulo: 'Agregar Usuario',
                    componente: FormUsuario
                }
            }
        },
        'Partidas': {
            opcion: {
                texto: 'Partidas',
                icono: 'fa-table-tennis-paddle-ball'
            },
            pantallas: {
                partidas: {
                    props: {},
                    titulo: 'Partidas',
                    componente: Partidas
                },
                partida: {
                    props: {},
                    titulo: 'Partida',
                    componente: Partida
                },
                form_partidas: {
                    props: {},
                    titulo: 'Organizar Partida',
                    componente: FormPartida
                },
            }
        },
        'Dispositivos': {
            opcion: {
                texto: 'Dispositivos',
                icono: 'fa-microchip'
            },
            pantallas: {
                dispositivos: {
                    props: {},
                    titulo: 'Dispositivos',
                    componente: Dispositivos
                },
                form_dispositivos: {
                    props: {},
                    titulo: 'Agregar Dispositivo',
                    componente: FormDispositivo
                },
            }
        },
        'Perfil': {
            pantallas: {
                perfil: {
                    props: {},
                    titulo: 'Mi Perfil',
                    componente: Perfil
                },
                editar_perfil: {
                    props: {},
                    titulo: 'Mi Información',
                    componente: EditarPerfil
                }
            }
        },
    }

    return (
        <Theme
            darkColor={config.colorSecundario}
            lightColor={config.colorPrimario}
            compareDark={config.colorPrimario}
        >
            <Navegador

                empresa={{
                    nombre: "BallSkore",
                    imagen: iconoEmpresa,
                    version: "v1.0.0-2409042 demo",
                }}
                usuario={{
                    nombre: usuario?.nombre,
                    rol: isAdmin() ? "Administrador" : "Jugador",
                    imagen: obtenerFoto(usuario?.foto, CARPETA_FOTO),
                    pantalla: "Perfil"
                }}
                componentes={ListaComponentes}
            ></ Navegador>
        </Theme>
    )
}

export default Index