import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useEffect, useState } from 'react'
import { useObtenerPartidasQuery, useEditarPartidaMutation } from '../../services/partidas'

const Partidas = ({ Push }) => {
    const { data } = useObtenerPartidasQuery()
    const [keys, setKeys] = useState([])
    const [partidas, setPartidas] = useState([])
    const [UpPartida, UpStatus] = useEditarPartidaMutation()

    useEffect(() => {
        if (data) {
            let partidas = data.length
            let terminadas = data.filter(item => item.estatus === 'terminado')
            let proceso = data.filter(item => item.estatus === 'proceso')
            let canceladas = data.filter(item => item.estatus === 'cancelado')
            setKeys([
                {
                    text: "Todas",
                    detalle: partidas,
                    icono: "fa-gamepad",
                    filtro: (item) => {
                        return true
                    }
                },
                {
                    text: "Terminadas",
                    detalle: terminadas.length,
                    icono: "fa-circle-check",
                    filtro: (item) => {
                        return item.item.estatus === 'terminado'
                    }
                },
                {
                    text: "Proceso",
                    detalle: proceso.length,
                    icono: " fa-spinner",
                    filtro: (item) => item.item.estatus === 'proceso'
                },
                {
                    text: "Canceladas",
                    detalle: canceladas.length,
                    icono: "fa-times",
                    filtro: (item) => item.estatus === 'cancelado'
                }
            ])
            let data_partidas = make_data(data)
            setPartidas(data_partidas)
        }
    }, [data])

    function make_data(data) {
        let marcador_total = 0
        return data.map(item => {
            item?.eureka_gm_juego_equipos?.forEach(equipo => {
                equipo?.eureka_gm_juego_marcadores?.forEach(marcador => {
                    marcador_total = parseInt(marcador.marcador) + marcador_total
                })
            })
            return {
                ...item,
                marcador: marcador_total,
                organizador: item.eureka_ac_usuario.nombre,
            }
        })

    }

    return (
        <Tabla
            checkbox={false}
            noData="No hay partidas organizadas"
            busqueda={{ placeholder: "Buscar...", keys: ["organizador"] }}
            keys={keys}
            headers={[
                { key: 'organizador', text: 'Organizador', type: 'text' },
                { key: 'fecha', text: 'Fecha', type: 'date' },
                // { key: 'marcador', text: 'Marcador', type: 'number' },
                { key: 'tipo', text: 'Tipo', type: 'text' },
                { key: 'estatus', text: 'Estatus', type: 'text' },
            ]}
            data={buildData(partidas, [
                { key: 'organizador', type: 'text' },
                { key: 'fecha', type: 'date' },
                // { key: 'marcador', type: 'number' },
                { key: 'tipo', type: 'text' },
                {
                    key: 'estatus',
                    type: 'text',
                    text: (item) => {
                        if (item.estatus === 'terminado') {
                            return <ChipEstatus texto="Terminado" tipo="Aceptado" />
                        } else if (item.estatus === 'proceso') {
                            return <ChipEstatus texto="En proceso" tipo="Pendiente" />
                        } else if (item.estatus === 'cancelado') {
                            return <ChipEstatus texto="Cancelado" tipo="Error" />
                        }
                    }
                },
            ])}
            // botonesHead={[
            //     {
            //         icono: 'fa-plus',
            //         tooltip: 'Organizar',
            //         onClick: () => {
            //             Push('form_partidas')
            //         }
            //     }
            // ]}
            botonesRow={[
                [
                    {
                        icono: "fa-flag-checkered",
                        tooltip: "Terminar",
                        mutacion: (item) => item.estatus === "proceso",
                        onClick: (item) => {

                            if (!UpStatus.isLoading) {
                                UpPartida({ id: item.id, data: { estatus: "terminado" } })
                            }
                        },
                    },
                ],
                [
                    {
                        icono: "fa-ban",
                        tooltip: "Cancelar",
                        mutacion: (item) => item.estatus === "proceso" && item.estatus !== "terminado",
                        onClick: (item) => {
                            if (!UpStatus.isLoading) {
                                UpPartida({ id: item.id, data: { estatus: "cancelado" } })
                            }
                        },
                    },
                    {
                        icono: "fa-play",
                        tooltip: "Reanudar",
                        mutacion: (item) => item.estatus === "cancelado",
                        onClick: (item) => {
                            if (!UpStatus.isLoading) {
                                UpPartida({ id: item.id, data: { estatus: "proceso" } })
                            }
                        },
                    },
                ]

            ]}
            onClickRow={(item) => {
                Push('partida', { partida: item.item.id })
            }}
        />
    )
}

export default Partidas