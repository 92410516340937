import nouser from "../imgs/nouser.jpg"
import { config } from "../config/config"

export const MESES = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
]

export const ESTATUS_USUARIOS = [
    { id: 'inactivo', text: 'Inactivo' },
    { id: 'activo', text: 'Activo' },
    { id: 'bloqueado', text: 'Bloqueado' },
]

/**
 * 
 * @param {string} cadena 
 * @returns 
 */
export const capitalize = (cadena = '') => {
    if (cadena && cadena.length > 0) {
        cadena = cadena.toLowerCase()
        let firstLetter = cadena.charAt(0)
        let firstLetterCap = firstLetter.toUpperCase()
        let remainingLetters = cadena.slice(1)
        return firstLetterCap + remainingLetters
    }
    else {
        return 'Sin Rol'
    }
}

/**
 * 
 * @param {float} number 
 * @param {int} decimals 
 * @param {string} decPoint 
 * @param {string} thousandsSep 
 * @param {string} dollarSimb 
 * @returns 
 */
export const numberFormat = (number = 0.0, decimals = 2, decPoint = '.', thousandsSep = ',', dollarSimb = '$') => {
    number = number ? number : 0
    number = Number(number).toFixed(decimals)
    let numberStr = String(number)
    let posDec = numberStr.indexOf('.')
    let numberIntStr = numberStr.slice(0, posDec)
    let decimalsStr = numberStr.slice(posDec).replace('.', decPoint)
    let digitsCount = 0
    let numberFormat = []


    for (let i = numberIntStr.length - 1; i >= 0; i--) {
        if (digitsCount === 3) {
            digitsCount = 0
            numberFormat.push(thousandsSep)
        }

        digitsCount++
        numberFormat.push(numberIntStr[i])
    }
    numberStr = numberFormat.reverse().join('') + decimalsStr

    if (dollarSimb) {
        return `${dollarSimb}${numberStr}`
    }
    else {
        return numberStr
    }
}

export const handlerMutation = async (mutacion, body, endQuery, errorQuery) => {
    if (mutacion && typeof mutacion === "function") {
        return await mutacion(body).unwrap()
            .then(endQuery ? endQuery : (data) => { })
            .catch((error) => { if (error?.status === 401) errorQuery ? errorQuery(error) : console.log(error) })
    }
}

/**
 * 
 * @param {Array} archivos 
 * @param {*} imagenError 
 * @param {string} carpeta 
 * @returns 
 */
export const obtenerLogo = (archivos = [], imagenError = null, carpeta = 'logo') => {
    if (archivos instanceof Array) {
        if (archivos.length > 0) {
            let multimedias = archivos.filter((multimedia) => {
                return multimedia.carpeta === carpeta
            })
            let multimedia = multimedias[0]
            if (multimedia)
                return `${config.urlCRUFDEKArchivo}/${multimedia.carpeta}/${multimedia.nombre}`
            else
                return imagenError ? imagenError : nouser
        }
        else {
            return imagenError ? imagenError : nouser
        }
    }
    else if (archivos instanceof Object) {
        let multimedia = archivos
        return `${config.urlCRUFDEKArchivo}/${multimedia.carpeta}/${multimedia.nombre}`
    }
    else return imagenError ? imagenError : nouser
}

/**
 * 
 * @param {Array} archivos 
 * @param {string} carpeta 
 * @returns 
 */
export const obtenerMisArchivos = (archivos = [], carpeta = 'logo') => {
    if (archivos.length > 0) {
        return archivos.filter((archivo) => {
            return archivo.carpeta === carpeta
        })
    }
    else return []
}

/**
 * 
 * @param {Array} archivos 
 * @param {string} carpeta 
 * @returns 
 */
export const obtenerMiArchivo = (archivos = [], carpeta = 'logo') => {
    if (archivos.length > 0) {
        archivos = archivos.filter((archivo) => {
            return archivo.carpeta === carpeta
        })
        return archivos.length > 0 ? archivos[0] : null
    }
    else return null
}

/**
 * 
 * @param {Array} datos 
 * @param {string} colID 
 * @param {string} colVal 
 * @returns 
 */
export const adaptarDatosSelector = (datos = [], colID = 'id', colVal = 'texto') => {
    return datos.map((dato) => {
        return {
            id: dato[colID],
            text: capitalize(dato[colVal]),
            item: dato,
        }
    })
}