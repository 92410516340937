import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

export const TABLA_DISPOSITIVO = 'EurekaBsDispositivo'

export const dispositivoAPI = createApi({
    reducerPath: 'dispositivoAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Dispositivos'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerDispositivo: builder.query({
            /**
             * 
             * @param {{ token: string, col: string, val: string, relaciones: array }} data 
             * @returns 
             */
            query: ({ id }) => {
                let body = new EkQuery(TABLA_DISPOSITIVO)
                    .with(['eureka_ac_usuario'])
                    .where(id, '=', id)

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.first()
                }
            },
        }),
        editarDispositivo: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: ({ id, data }) => {
                let body = new EkQuery(TABLA_DISPOSITIVO)
                if (id) body = body.where("id", id).update(data)
                else body = body.create(data)
                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
            invalidatesTags: ['Dispositivos'],
        }),
        obtenerInfoDispositivo: builder.mutation({
            /**
             * 
             * @param {{ id: number }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_DISPOSITIVO).where('id', data.id).first()
                }
            },
        }),
        obtenerDispositivos: builder.query({
            /**
             * @param {{ id: number }} data 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_DISPOSITIVO)
                        .with(['eureka_ac_usuario'])
                        .get()
                }
            },
            providesTags: ['Dispositivos'],
        }),
    }),
})


export const {
    useObtenerDispositivosQuery,
    useObtenerDispositivoQuery,
    useEditarDispositivoMutation,
    useObtenerInfoDispositivoMutation,
} = dispositivoAPI