import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useEditarUsuarioMutation, useObtenerUsuariosQuery } from '../../services/usuario'
import { NOM_FORM_USUARIO } from './FormUsuario'
import { useEffect, useState } from 'react'

const Usuarios = ({ usuario, Push, ShowAlert }) => {
    const { data, isLoading, refetch } = useObtenerUsuariosQuery({ id: usuario.id })
    const [editarUsuario] = useEditarUsuarioMutation();
    const [keys, setKeys] = useState([]);


    useEffect(() => {
        if (data) {
            let usuarios = data.length
            let activos = data.filter(item => item.estatus === 'activo')
            let archivados = data.filter(item => item.estatus === 'inactivo')
            setKeys([
                {
                    text: "Todos",
                    detalle: usuarios,
                    icono: "fa-user",
                    filtro: (item) => {
                        return true
                    }
                },
                {
                    text: "Activos",
                    detalle: activos.length,
                    icono: "fa-circle-check",
                    filtro: (item) => {
                        return item.item.estatus === 'activo'
                    }
                },
                {
                    text: "Archivados",
                    detalle: archivados.length,
                    icono: "fa-user-slash",
                    filtro: (item) => item.item.estatus === 'inactivo'
                },
            ])
        }
    }, [data])

    return (
        <Tabla
            checkbox={false}
            noData="No hay usuarios registrados"
            busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
            headers={[
                { key: 'nombre', text: 'Nombre', type: 'text' },
                { key: 'correo', text: 'Correo', type: 'text' },
                { key: 'telefono', text: 'Teléfono', type: 'text' },
                { key: 'estatus', text: 'Estatus', type: 'text' },
            ]}
            keys={keys}
            data={buildData(data ?? [], [
                { key: 'nombre', type: 'text' },
                { key: 'correo', type: 'text' },
                { key: 'telefono', type: 'text' },
                {
                    key: 'estatus',
                    type: 'text',
                    text: (item) => {
                        let tipo = item.estatus === 'activo' ? 'Aceptado' : 'Error'
                        return <ChipEstatus texto={item.estatus} tipo={tipo} />
                    }
                },
            ])}

            botonesHead={[
                {
                    icono: 'fa-plus',
                    tooltip: 'Registrar',
                    onClick: () => {
                        Push('form_usuarios', { [NOM_FORM_USUARIO]: {} })
                    }
                }
            ]}
            botonesRow={[
                {
                    icono: 'fa-pen',
                    tooltip: 'Editar',
                    onClick: (item) => {
                        Push('form_usuarios', { [NOM_FORM_USUARIO]: { ...item.item } })
                    }
                },
                {
                    icono: 'fa-rotate',
                    tooltip: 'Cambiar Estatus',
                    onClick: (item) => {
                        let data = item.item
                        let estatus = data.estatus === 'activo' ? 'inactivo' : 'activo'
                        editarUsuario({ id: data.id, body: { estatus: estatus } })
                            .then(() => { refetch() })
                    }
                },
            ]}
            onClickRow={() => { }}
        />
    )
}

export default Usuarios