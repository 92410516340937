import { configureStore } from '@reduxjs/toolkit'
import sesionReducer from '../feactures/SesionSlice'
import { usuarioAPI } from '../services/usuario'
import { multimediaAPI } from '../services/multimedia'
import { partidaAPI } from '../services/partida'
import { dispositivoAPI } from '../services/dispositivo'
import { partidasAPI } from '../services/partidas'
export const store = configureStore({
    reducer: {
        sesionSlice: sesionReducer,
        [usuarioAPI.reducerPath]: usuarioAPI.reducer,
        [multimediaAPI.reducerPath]: multimediaAPI.reducer,
        [partidaAPI.reducerPath]: partidaAPI.reducer,
        [partidasAPI.reducerPath]: partidasAPI.reducer,
        [dispositivoAPI.reducerPath]: dispositivoAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({ serializableCheck: false }),
        usuarioAPI.middleware,
        multimediaAPI.middleware,
        partidaAPI.middleware,
        partidasAPI.middleware,
        dispositivoAPI.middleware,
    ],
})