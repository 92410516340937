import { Formulario, Input, ImageInput, InputSearch, Miniatura } from "eureka-design"
import { useEditarDispositivoMutation } from "../../services/dispositivo"
import { useSearchUsuariosQuery } from "../../services/usuario"
import { useEffect, useState } from 'react';
import { handlerMutation } from "../../js/util.js"
import { obtenerFoto } from '../../js/multimedia'

export default function FormDispositivo({ UpProps, dispositivo, numero_identificador, descripcion, Propietario_nombre, Propietario_id }) {
    const [idDispositivo, setIdDispositivo] = useState(dispositivo);
    const [Propietario_value, setPropietarioValue] = useState(Propietario_nombre);
    const [Propietario, setPropietario] = useState(Propietario_id);
    const [searchPropietario, setSearchPropietario] = useState(null)

    const [crearDispositivo, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useEditarDispositivoMutation();
    const { data: Usuarios, isLoading: usuariosEstatus } = useSearchUsuariosQuery({ search: searchPropietario })

    const handlerInput = (value, nombre) => {
        let fecha1 = new Date()
        fecha1 = `${fecha1.getFullYear()}-${fecha1.getMonth() + 1}-${fecha1.getDate()} ${fecha1.getHours()}:${fecha1.getMinutes()}:00`
        handlerMutation(crearDispositivo, { id: idDispositivo, data: { [nombre]: value, tipo: "otro", fecha_alta: fecha1 } }, (r) => {
            if (r?.mensaje?.id) {
                setIdDispositivo(r.mensaje.id)
                UpProps({ dispositivo: r.mensaje.id })
            }
        })
        UpProps({ [nombre]: value })
    }

    const handlerUsuarios = () => {
        let usuarios = Usuarios?.usuarios?.map((item, index) => {
            return {
                value: item,
                text: item.nombre,
                icono: () => {
                    return <Miniatura
                        key={index}
                        avatar={true}
                        error={"/img/noimage.jpg"}
                        src={obtenerFoto(item.foto, "bs_usuarios")}
                    />
                }
            }
        });
        return usuarios;
    }

    return (
        <Formulario titulo="Agrega un nuevo dispositivo"
            isLoading={[inputIsloading]}
            isError={[inputIsError]}
            isSuccess={[inputIsSuccess]}>

            <Input
                max={255}
                min={2}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Numero de identificador"}
                nombre={"numero_identificador"}
                type={"text"}
                value={numero_identificador}
                iconLeft={"fa-microchip"}
            />
            <Input
                max={255}
                min={2}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Detalles"}
                nombre={"descripcion"}
                type={"textarea"}
                value={descripcion}
                iconLeft={" fa-info"}
            />
            <InputSearch
                value={Propietario_value}
                changeFunction={(value) => {
                    setSearchPropietario(value)
                }}
                OpcionSelect={(item) => {

                    setSearchPropietario(null)
                    // setPropietarioValue(item.nombre)
                    // setPropietario(item.id)
                    handlerInput(item.id, "usuario")
                }}
                isLoading={usuariosEstatus}
                opciones={Usuarios?.usuarios ? handlerUsuarios() : []}
                iconLeft={"fa-solid fa-map-marker"}
                placeholder={"Propietario del dispositivo"}
            />
        </Formulario>
    )
}
