import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

export const TABLA_USUARIO = 'EurekaAcUsuario'
const TABLA_RELACION_ROL = "EurekaCrRolUsuario"

export const usuarioAPI = createApi({
    reducerPath: 'usuarioAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Usuarios'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerUsuario: builder.query({
            /**
             * 
             * @param {{ token: string, col: string, val: string, relaciones: array }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_USUARIO).where(data.col, '=', data.val)

                if (data.relaciones.length > 0) {
                    let relaciones = data.relaciones
                    for (let i = 0; i < relaciones.length; i++) {
                        body = body.with(relaciones[i])
                    }
                }
                // else no relacionar

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${data?.token ?? token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.first()
                }
            },
        }),
        editarUsuario: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_USUARIO)
                if (data?.id) body = body.where("id", data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        uploadImageUsuarios: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_USUARIO)
                    .uploadFile(data.file, data.carpeta, data.nombre);

                if (data.id != null) {
                    body = new EkQuery(TABLA_USUARIO)
                        .where("id", data.id)
                        .uploadFile(data.file, data.carpeta, data.nombre);
                }
                var formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                });
                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token()}`,

                    },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            },
            invalidatesTags: ["usuarios", "roles"]
        }),
        getRoles: builder.query({
            query: ({ usuario = 0 }) => {
                let body = new EkQuery("EurekaCrRol")
                    .with("eureka_cr_rol_usuarios", (query) => {
                        return query.where("usuario", usuario === null ? 0 : usuario)
                            .where("rol", "!=", "NULL")
                    })
                    .get();

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }

            },
            providesTags: ["roles"],
            // transformResponse: resp => (resp.data),
        }),
        agregarRol: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaCrRolUsuario")
                    .create(data);
                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
            invalidatesTags: ["roles"]
        }),
        removerRol: builder.mutation({
            query: ({ rol, usuario }) => {
                let body = new EkQuery("EurekaCrRolUsuario")
                    .where("rol", rol)
                    .where("usuario", usuario)
                    .delete();
                console.log("removido el rol", rol, "al usuario", usuario)
                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
            invalidatesTags: ["roles"]
        }),
        obtenerInfoUsuario: builder.mutation({
            /**
             * 
             * @param {{ id: number }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_USUARIO).where('id', data.id).first()
                }
            },
        }),
        obtenerUsuarios: builder.query({
            /**
             * @param {{ id: number }} data 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_USUARIO).where('id', '!=', data.id).get()
                }
            },
        }),
        searchUsuarios: builder.query({
            /**
             * @param {{ id: number }} data 
             */
            query: ({ search }) => {
                let body = new EkQuery(TABLA_USUARIO).where('id', 0)
                if (search && search != "") body = new EkQuery(TABLA_USUARIO).search(search, ["nombre"])


                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.paginate(100, 1)
                }
            },
            transformResponse: resp => ({
                usuarios: resp.data,
                current_page: resp.current_page,
                last_page: resp.last_page
            }),
        }),
        obtenerRoles: builder.query({
            query: (data) => {
                let body = new EkQuery(TABLA_RELACION_ROL)
                    .with(["eureka_cr_rol.eureka_cr_roles"])
                    .where("rol", "!=", "NULL")
                    .where("usuario", "=", data.usuarioID)
                    .get()

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
    }),
})


export const {
    useObtenerUsuarioQuery,
    useEditarUsuarioMutation,
    useObtenerInfoUsuarioMutation,
    useObtenerUsuariosQuery,
    useSearchUsuariosQuery,
    useObtenerRolesQuery,
    useUploadImageUsuariosMutation,
    useGetRolesQuery,
    useAgregarRolMutation,
    useRemoverRolMutation
} = usuarioAPI